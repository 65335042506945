header {
  background-image: linear-gradient(rgba(black, 0.3), rgba(black, 0.3)),
    url('https://s3.amazonaws.com/djones-imghost/Jtr5kSqw/2012-06-09.14-24-28.9d77e960.jpg');
  background-size: cover;
  background-position: 0% 35%;
  height: 128px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  a {
    text-decoration: none;
  }
  .title {
    font-size: 1.8rem;
    margin: 0.5em;
    color: white;
  }
  @media (min-width: $bpSmall) {
    align-items: center;
    .title {
      font-size: 3rem;
    }
  }
}
