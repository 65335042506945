html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
}

body > .content,
body > .content-full-width {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0 1rem;
  overflow-x: hidden;
}

body > .content {
  width: 100%;
  max-width: 40em;
  align-self: center;
}

body.news > .container {
  flex-grow: 1;
  width: 100%;
  max-width: $bpMedium;
  margin: 0 auto;
}

body.news > .container > * {
  max-width: 100%;
}

@supports (display: grid) {
  @media (min-width: $bpSmall) {
    body.news > .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

body.location iframe {
  flex-grow: 1;
  border: 0;
}

.sixteen-nine {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
