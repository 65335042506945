@media (min-width: $bpSmall) {
  #nav {
    background-color: $cBackground;
    background-image: linear-gradient(
      20deg,
      mix(black, $cBackground, 25%),
      mix(white, $cBackground, 10%)
    );
  }
}

@media (max-width: $bpSmall - 1px) {
  #nav {
    z-index: 2;
    position: relative;
  }
  #nav > div {
    position: fixed;
    z-index: 1;
    ul {
      overflow-y: scroll;
    }
  }
}

#nav > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(black, 0.8);

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    background-color: rgba(white, 0.8);
    margin: 1px;
    width: 16em;
  }

  a {
    font-family: 'Open Sans', sans-serif;
    display: block;
    padding: 0.3em 1em;
    color: black;
    text-decoration: none;
  }

  @media (min-width: $bpSmall) {
    position: static;
    display: block;
    width: $bpSmall;
    margin: 0 auto;
    background-color: transparent;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin: 0 1em;
      width: auto;
      background-color: transparent;
    }

    a {
      color: white;
    }
  }

  @media (min-width: $bpLarge) {
    width: $bpLarge;
  }
}

#nav-expand {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  border: 0;
  padding: 1em;
  background-color: rgba(white, 0.8);
}

#small_bp_trigger {
  display: none;
  @media (min-width: $bpSmall) {
    display: block;
  }
}
