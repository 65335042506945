$cBackground: #245;
$cText: #333;

body {
  background-color: white;
  color: $cText;
}

a {
  color: mix(black, cornflowerblue, 40%);
}

a:visited {
  color: mix(black, purple, 40%);
}
