#photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .photo {
    width: 144px;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      border: 3px solid white;
      box-shadow: $small_shadow;
    }
  }
}

.modal {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(black, 0.8);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-family: monospace;
    color: white;
    font-size: 4rem;
    background-color: rgba(black, 0.4);
    border: 0;
    padding: 0.5rem;
    line-height: 1;
    box-shadow: $small_shadow;
  }

  .modal-close {
    position: fixed;
    top: 0;
    right: 0;
  }
}
