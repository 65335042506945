body.news {
  .feature-photo {
    position: relative;
    background-color: $cBackground;
    background-image: url('/images/flite-test-2014.jpg');
    background-position: center top;
    background-size: cover;
    .text {
      font-size: 0.8em;
      background-color: rgba(black, 0.5);
      position: absolute;
      box-sizing: border-box;
      max-width: 100%;
      padding: 1em;
      bottom: 0;
      right: 0;
      a {
        color: white;
      }
    }
  }
  .feature-video {
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .announcements,
  .events {
    overflow: hidden;
    padding: 0 1rem;
    background-color: $cBackground;
    color: white;
    a {
      color: white;
    }
    ul {
      padding-left: 2em;
    }
    @media (min-width: $bpSmall) {
      margin: 1rem;
      border-radius: 1rem;
      box-shadow: $small_shadow;
    }
  }

  .cta {
    padding: 1rem;
    grid-column: 1 / 3;
  }

  .content {
    margin: 0 auto;
    padding: 1rem;
    grid-column: 1 / 3;
  }
}
